/* ------------- call --------------- */
.contact-button{
    position: fixed;
    top: 45%;
    right: 20px;
    display: grid;
    gap: 20px;
    z-index: 1;
}

.call-button {
    animation: play 1.5s ease infinite;
    /* background-color: #fe0000; */
    border-radius: 50%;
    font-size: 1.9rem;
    /* padding: 15px 20px; */
    padding: 13px 0px;
  }
  
  .call-button-icon {
    padding: 15px 20px;
    border-radius: 50%;
    color: white;
  
    background-color: #fe0000;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.5),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.5),
      inset -4px -4px 6px 1px rgba(255, 255, 255, 0.227),
      inset 5px 4px 6px 2px rgba(0, 0, 0, 0.412);
  }
  
  .call-button i {
    animation: shake 1.5s ease infinite;
  }
  
  /* -------------- whatsapp ------------- */
  
  .whatsapp-button {
    display: flex;
  }
  .whatsapp-button-icon {
    background: #25d366;
    /* font-size: 40px; */
    font-size: 2.5rem;
  
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 15px 18px;
    border-radius: 50%;
    text-decoration: none;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.222),
      -4px -4px 6px 0 rgba(116, 125, 136, 0.297),
      inset -4px -4px 6px 0 rgba(255, 255, 255, 0.088),
      inset 4px 4px 6px 0 rgba(0, 0, 0, 0.4);
  }
  
  .whatsapp-button-icon::before {
    content: "";
    position: absolute;
    border-radius: 50%;
    border: 5px solid #1feb6a;
    animation-name: pulse-border;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
  }

  @media only screen and (max-width: 700px) {
    .contact-button{
        top: 50%;
        right: 5px;
        gap: 15px;
    }

    .call-button {
        font-size: 1.5rem;
        padding: 10px 0px;
      }
    .call-button-icon {
      padding: 11px 16px;
    
    }
    
      .whatsapp-button-icon {
        padding: 12px 14px;
        font-size: 2rem;
      }
    
      .whatsapp-button-icon::before {
        border: 3px solid #1feb6a;
      }
    
}