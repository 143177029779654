.gallery{
    background-color: rgba(164, 164, 164, 0.137);
}
.gallery>div{
    max-width: 1200px;
    margin: auto;
    display: grid;
    gap: 20px;
    padding: 30px 0;
   
}
.gallery-grid-1{
    display: grid;
    gap: 20px;
    grid-template-columns: 2fr 1fr;
    height: 500px;
    margin-top: -30px;
}


.gallery-grid-2>div:nth-child(1){
    grid-area: a;
}

.gallery-grid-2>div:nth-child(2){
    grid-area: b;
}

.gallery-grid-2>div:nth-child(3){
    grid-area: c;
}

.gallery-grid-2>div:nth-child(4){
    grid-area: d;
}

.gallery-grid-2>div:nth-child(5){
    grid-area: e;
}

.gallery-grid-2{
    display: grid;
    gap: 20px;
    grid-template-areas: "a a b c" "a a d e";
    width: 100%;
    height: 600px;
    margin: auto;
}
 



.gallery-grid-3>div:nth-child(1){
    grid-area: a;
}

.gallery-grid-3>div:nth-child(2){
    grid-area: b;
}

.gallery-grid-3>div:nth-child(3){
    grid-area: c;
}

.gallery-grid-3>div:nth-child(4){
    grid-area: d;
}

.gallery-grid-3>div:nth-child(5){
    grid-area: e;
}

.gallery-grid-3{
    display: grid;
    gap: 20px;
    grid-template-areas: "a b c c" "d e c c";
    width: 100%;
    height: 600px;
    margin: auto;
}
 
.gallery-grid-4{
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    height: 300px;
}


.gallery-img-1{
    background: url("../../media/images/gallery-images/1eec8b29183683452f46ad72a9381af5.jpg");
}


.gallery-img-2{
    background: url("../../media/images/gallery-images/2d2b8b1b48bbf6cfe3a864c86efc31dd.jpg");
}

.gallery-img-3{
    background: url("../../media/images/gallery-images/5a0148dae33c6eeace39b04340b473c2.jpg");
}

.gallery-img-4{
    background: url("../../media/images/gallery-images/5ea7be07da631.png");
}

.gallery-img-5{
    background: url("../../media/images/gallery-images/5ea7be66bc887.png");
}

.gallery-img-6{
    background: url("../../media/images/gallery-images/5ea7be9232fe8.png");
}

.gallery-img-7{
    background: url("../../media/images/gallery-images/5ea7bee11567b.png");
}

.gallery-img-8{
    background: url("../../media/images/gallery-images/5ea7bf0d693d2.png");
}

.gallery-img-9{
    background: url("../../media/images/gallery-images/5ea7bf7d0f02e.png");
}

.gallery-img-10{
    background: url("../../media/images/gallery-images/5ea7bf516a13d.png");
}

.gallery-img-11{
    background: url("../../media/images/gallery-images/5ea7c1aadcdd1.png");
}

.gallery-img-12{
    background: url("../../media/images/gallery-images/5ea7c2b27cbf5.png");
}

.gallery-img-13{
    background: url("../../media/images/gallery-images/5ea7c3bb04e28.png");
}

.gallery-img-14{
    background: url("../../media/images/gallery-images/5ea7c06f37ce4.png");
}

.gallery-img-15{
    background: url("../../media/images/gallery-images/5ea7c6cf73b23.png");
}

.gallery-img-16{
    background: url("../../media/images/gallery-images/5ea7c7c35a64a.png");
}

.gallery-img-17{
    background: url("../../media/images/gallery-images/5ea7c20da15b5.png");
}

.gallery-img-18{
    background: url("../../media/images/gallery-images/5ea7c24f84726.png");
}

.gallery-img-19{
    background: url("../../media/images/gallery-images/5ea7c34f864bd.png");
}

.gallery-img-20{
    background: url("../../media/images/gallery-images/5ea7c811af20b.png");
}

.gallery-img-21{
    background: url("../../media/images/gallery-images/5ea7c6027e824.png");
}

.gallery-img-22{
    background: url("../../media/images/gallery-images/89651fc837f9d105d66e205f716a5220.png");
}

.gallery-img-23{
    background: url("../../media/images/gallery-images/20220208_183525.jpg");
}

.gallery-img-24{
    background: url("../../media/images/gallery-images/a4b03db7-8ce4-456d-8297-42875ab0bd7f-large16x9_GettyImages1185232243.jpg");
}

.gallery-img-25{
    background: url("../../media/images/gallery-images/Best-Dog-Clippers-for-Maltese.jpg");
}

.gallery-col{
    background-color: rgb(228, 228, 228);
    /* border-radius: 10px; */
    
    background-size: cover;
    background-position: center;
}



@media only screen and (max-width: 700px) {

    .gallery>div{
        max-width: 1200px;
        /* margin: 30px 15px; */
        display: grid;
        gap: 10px;
    padding: 10px 15px 30px;
       
    }
    

    .gallery-grid-1,
    .gallery-grid-4{
        gap: 10px;
        height: 150px;
    margin-top: 0px;

    }

    .gallery-grid-2{
        display: grid;
        gap: 10px;
        grid-template-areas: "a b" "c c" "d e";
        width: 100%;
        height: 550px;
        margin: auto;
    }
     

    .gallery-grid-3{
        display: grid; 
        gap: 10px;
        grid-template-areas: "a b" "c c" "d e";
        width: 100%;
        height: auto;
        margin: auto;
    }
    .gallery-grid-3>div{
        height: 180px;
    }

.gallery-img-10{
    height: 350px !important;
}
     
        
}

