
.landing-page{
    position: relative;
}

.landing-page-navbar{
    position: sticky;
    top: 0;
    z-index: 1;
}

.landing-page
.testimonial-component-heading{
    padding-bottom: 0;
}

.landing-page
.testimonial-component-header{
    justify-content: center;
}

/* =============================================================================== */
/* header =============================================================================== */
/* =============================================================================== */


.landing-header{
    background: url(../../media/images/landing-page-1.jpg);
    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.landing-header>div{
    background-color: #0009;
}
.landing-header-main{
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 40px;
    padding: 40px 60px;
    align-items: center;
    max-width: 1200px;
    margin: auto;
}

.landing-header-heading {
    font-size: 2.5rem;
    color: #fff;
    text-align: center;
    font-weight: 600;
    /* font-family: "playpen sans"; */
    margin-bottom: 5px;
}
.landing-header-heading span{
    /* font-family: "playpen sans"; */
}

.landing-header-heading >span>span{
    color: #f00;
    background-color: #ffffffee;
    padding: 0 30px;
    border-radius: 30px;
    text-transform: capitalize;
    font-weight: 700;
}


.landing-header-form>form{
    background-color: #fffafa;
    border-radius: 30px;
    padding: 30px;
    display: grid;
    gap: 20px;
}

.landing-header-form-heading{
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
}

.landing-header-form>form>input{
    padding: 12px 20px;
    font-size: .9rem;
    border-radius: 20px;
    border: 1px solid #80808047;
}
.landing-header-form>form>button{
    background-color: #f00;
    color: #fff;
    padding: 10px;
    font-size: 1.1rem;
    border-radius: 20px;
    border: none;
}


.landing-header-form-animate{
    animation: shake .8s ease-in-out ;
}






/* =============================================================================== */
/* footer =============================================================================== */
/* =============================================================================== */

.landing-page
.footer-c-col-2{
align-items: center;
}



/* =============================================================================== */
/* =============================================================================== */
/* =============================================================================== */

.landing-page-book-button{
    margin: auto;
    text-align: center;
    padding: 30px 0;
    
}
.landing-page-book-button>a{
    background-color: #f00;
    color: #fff;
    width: max-content;
    padding: 10px 50px;
    font-size: 1.3rem;
    font-weight: 600;
    border-radius: 30px;
    cursor: pointer;
    text-decoration: none;
    
}




/* =============================================================================== */
/* =============================================================================== */



@media only screen and (max-width: 1000px) {


.landing-header{
    background: none;
    margin: 20px;
}
.landing-header>div{
    border-radius: 30px;
}
.landing-header-main{
    grid-template-columns: 1fr;
    padding: 0;
    gap: 0;
}

.landing-header-content{
    background: url(../../media/images/landing-page-1.jpg);
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius:  30px 30px 0 0;
}

.landing-header-content>div{
    border-radius:  30px 30px 0 0;
    background-color: #0009;
    padding: 70px 5px;
}

.landing-header-heading {
    font-size: 1.3rem;
    font-weight: 600;
}


.landing-header-heading >span>span{
    padding: 0 20px;
}


.landing-header-form>form{
    border-radius: 0 0 30px 30px;
    padding: 30px 20px 30px;
    gap: 15px;
}

.landing-header-form-heading{
    font-size: 1.1rem;
}

.landing-header-form>form>input{
    
    border: 1px solid #80808047;
    border-radius: 20px;
    font-size: .9rem;
    padding: 8px 15px;

}
    
.landing-header-form>form>button{
    padding: 7px;

}



/* =============================================================================== */
/* =============================================================================== */
/* =============================================================================== */


.landing-page-book-button{
    padding: 20px 0;
    
}

.landing-page-book-button>a{
    padding: 10px 30px;
    font-size: .9rem;
}




/* =============================================================================== */
/* footer =============================================================================== */
/* =============================================================================== */

.landing-page
.footer-social {
    margin: 0;
}



}


