
.header-numbers {
    background-color: rgba(187, 187, 187, 0.156);
    padding: 40px 0;
}
.header-numbers>div {
    margin: auto;
    max-width: 1170px;
    
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  .header-numbers-col {
    text-align: center;
  }
  .header-numbers-col-inc {
    font-size: 2rem;
    font-weight: 700;
    /* color: rgb(104, 49, 49); */
  }
  .header-numbers-col-inc > span {
    color: rgb(255, 0, 0);
    font-size: 2.5rem;
  }
  .header-numbers-col-txt {
    font-size: 1.2rem;
    font-weight: 400;
    color: rgb(50, 50, 50);
  }
  
@media only screen and (max-width: 700px) {
    
  .header-numbers {
    padding: 20px 5px;
  }

  .header-numbers-col-inc {
    font-size: 1rem;
  }

  .header-numbers-col-inc span {
    font-size: 1.4rem;
  }

  .header-numbers-col-txt {
    font-size: 0.8rem;
    line-height: 1.4;
  }
}
