
/* HTML: <div class="loader"></div> */
.loader {
  width: 88px;
  height: 12px;
  --_g: no-repeat radial-gradient(farthest-side,#000 94%,#0000);
  background:
    var(--_g) 25% 0,
    var(--_g) 75% 0;
  background-size: 12px 12px;
  position: relative;
  animation: l24-0 1s linear infinite;
}
.loader:before {
  content: "";
  position: absolute;
  height: 12px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #000;
  inset: 0;
  margin: auto;
  animation: l24-1 1s cubic-bezier(0.5,300,0.5,-300) infinite;
}
@keyframes l24-0 {
  0%,24%  {background-position: 25% 0,75% 0}
  40%     {background-position: 25% 0,85% 0}
  50%,72% {background-position: 25% 0,75% 0}
  90%     {background-position: 15% 0,75% 0}
  100%    {background-position: 25% 0,75% 0}
}
@keyframes l24-1 {
  100% {transform:translate(0.1px)}
}



/* ---------------------------------------------------------- */

@keyframes anim-popoutin {
  0% {
    color: #fe0000;
    opacity: 1;
    text-shadow: 0 0 0 #4b4b4b00;
    transform: scale(1);
}
50% {
  opacity: 1;
  text-shadow: 7px 7px 2px rgba(186, 186, 186, 0.499);
  transform: scale(1.2);
}
100% {
  color: #fe0000;
    opacity: 1;
    text-shadow: 1px 0 0 #4e4e4e00;
    transform: scale(1);
}
}
/* ------------------------------- */
@keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 rgba(37, 211, 102, 0.5);
    }
    80% {
      box-shadow: 0 0 0 14px rgba(37, 211, 102, 0);
    }
  }
  
  @keyframes pulse-border {
    0% {
      padding: 35px;
      opacity: 0.75;
    }
    75% {
      padding: 50px;
      opacity: 0;
    }
    100% {
      opacity: 0;
    }
  }
  
  
@keyframes play {
    0% {
      transform: scale(1);
    }
    15% {
      box-shadow: 0 0 0 5px #fd272760;
    }
    25% {
      box-shadow: 0 0 0 10px #ff373797, 0 0 0 20px #fe00009a;
    }
    25% {
      box-shadow: 0 0 0 15px #ff2d2d60, 0 0 0 30px #ff353565;
    }
  }
  
  
  
@keyframes shake {
    0% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    5% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    10% {
      transform: translate(-3px, 0px) rotate(1deg);
    }
    15% {
      transform: translate(3px, 2px) rotate(0deg);
    }
    20% {
      transform: translate(1px, -2px) rotate(1deg);
    }
    25% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }
    30% {
      transform: translate(-3px, 1px) rotate(0deg);
    }
    35% {
      transform: translate(3px, -2px) rotate(-1deg);
    }
    40% {
      transform: translate(-1px, 3px) rotate(1deg);
    }
    45% {
      transform: translate(2px, 0px) rotate(0deg);
    }
    50% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }
    55% {
      transform: translate(-3px, 1px) rotate(-1deg);
    }
    60% {
      transform: translate(1px, -2px) rotate(0deg);
    }
    70% {
      transform: translate(-2px, 1px) rotate(0deg);
    }
    80% {
      transform: translate(3px, -1px) rotate(0deg);
    }
    90% {
      transform: translate(0px, 0px) rotate(0deg);
    }
    100% {
      transform: translate(0px, 0px) rotate(0deg);
    }
  }
  
  
  
@keyframes wiggle {
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}

@keyframes blink {
  0%, 10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90%, 100% {
    opacity: 0;
  }
}


@keyframes gradient {
	0% {
		background-position: 50% 0% ;
	}
	50% {
		background-position: 50% 100%;
	}
	100% {
		background-position: 50% 0%;
	}
}


  
  
  
@media only screen and (max-width: 800px) {
  
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 3px #fd272760;
  }
  25% {
    box-shadow: 0 0 0 7px #ff373797, 0 0 0 17px #fe00009a;
  }
  25% {
    box-shadow: 0 0 0 12px #ff2d2d60, 0 0 0 25px #ff353565;
  }
}


@keyframes pulse-border {
  0% {
    padding: 28px;
    opacity: 0.75;
  }
  75% {
    padding: 40px;
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}




}
  
  
  