.about-us-com{
    padding: 50px;
    
}
.about-us-com>div{
    max-width: 1170px;
    margin: auto;
}

.about-us-com-heading-1{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    color: #fe0000;
    margin-bottom: 20px;
}
.about-us-com-text-1{
    text-align: center;
}

.about-us-com-heading-2{
    font-size: 2rem;
    font-weight: 700;
    margin: 30px 0 10px;
}
.about-us-com-heading-2 span{
    color: #fe0000;
    
}

@media only screen and (max-width: 700px) {
    .about-us-com{
        padding: 30px 15px;
        
    }    
    .about-us-com-heading-1{
        font-size: 1.8rem;
        margin-bottom: 5px;
    }
    .about-us-com-text-1{
        font-size: .9rem;
    }
    
    .about-us-com-heading-2{
        font-size: 1.5rem;
        font-weight: 700;
        margin: 20px 0 10px;
    }
    .about-us-com-text-2{
        font-size: .9rem;
    }
}
