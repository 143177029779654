.admin-nav{
    display: flex;
    justify-content: space-between;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
    padding: 10px 100px;
    align-items: center;
  margin-bottom: 30px;

}

.admin-nav-logo>img{
    width: 250px;
}
.admin-nav-button{
    background-color: #fe0000;
    color: white;
    padding: 5px 30px;
    border-radius: 30px;
    font-weight: 500;
    font-size: 1.2rem;
}

@media only screen and (max-width: 700px) {

    .admin-nav{
        padding: 5px 10px;
    }
    
    .admin-nav-logo>img{
        width: 180px;
    }
    .admin-nav-button{
        padding: 2px 20px;
        font-size: 1rem;
    }
}
