.s-blog{
    padding: 50px;
}
.s-blog>div{
    max-width: 1170px;
    margin: auto;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 50px;
}

.s-blog-col-1>a{
    color: #fe0000;
    font-size: 1.1rem;
    text-decoration: none;
    font-weight: 500;

}
.s-blog-col-1>div>div{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
    background-color: rgba(181, 181, 181, 0.212);
    background-color: white;
    padding: 30px;
}

.s-blog-col-1-heading-1{
    font-size: 1.8rem;
    font-weight: 700;
    margin-bottom: 20px;
}
.s-blog-col-1-heading-1 span{
color: #fe0000;
}
.s-blog-col-1-head{
    background-color: #fe0000;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-weight: 600;
    /* margin: 10px 0 20px; */
}

.s-blog-col-1-img{
    width: 90%;
    margin: auto;
}
.s-blog-col-1-img>img{
    width: 100%;
    border-radius: 10px;
    box-shadow: 8px 10px 10px #0000007d;
    box-shadow: 5px 5px 20px #00000066;
    margin: 20px 0;
}

.s-blog-col-1-heading-2{
    font-size: 1.3rem;
    font-weight: 600;
    color: #fe0000;
}
.s-blog-col-1-text{
    margin-bottom: 20px;
    }
    
    .s-blog-col-1-text>a{
        color: #fe0000;
        text-decoration: none;
        }
        .s-blog-col-1-text>a:hover{
            color: blue;
        }


.s-blog-col-2{
}
.s-blog-col-2>div{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
    background-color: white;
    padding: 20px;
    display: grid;
    gap: 20px;
}

.s-blog-col-2-head{
    background-color: #fe0000;
    color: white;
    font-weight: 600;
    font-size: 1.2rem;
    padding: 5px 20px;
}


.s-blog-col-2-list{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
    /* background-color: rgba(181, 181, 181, 0.166); */
    padding: 10px;
}
.s-blog-col-2-list-heading{
    color: #fe0000;
    font-size: 1.1rem;
    font-weight: 600;
}
.s-blog-col-2-list-link{
    text-decoration: none;
    color: #fe0000;
    font-weight: 500;
}
.s-blog-col-2-list-link:hover{
    color: rgb(174, 0, 0);
}

@media only screen and (max-width: 700px) {

    .s-blog{
        padding: 20px 15px;
    }
    .s-blog>div{
        grid-template-columns: 1fr;
    }
    
    .s-blog-col-1>div>div{
        box-shadow: rgba(0, 0, 0, 0.442) 0px 2px 8px 0px;
        background-color: rgba(181, 181, 181, 0.212);
        background-color: white;
        padding: 15px;
    }
    
    .s-blog-col-1-heading-1{
        font-size: 1.4rem;
        margin-bottom: 10px;
        line-height: 1.3;
    }
    .s-blog-col-1-head{
        padding: 2px 10px;
        font-size: .9rem;
    }
    
    .s-blog-col-1-img>img{
        box-shadow: 6px 7px 7px #0000006b;
        margin: 15px 0 10px;
    }
    
    .s-blog-col-1-heading-2{
        font-size: 1.1rem;
    }
    .s-blog-col-1-text{
        font-size: .9rem;
    margin-bottom: 10px;
    }
    
    
    
    .s-blog-col-2{
    }
    .s-blog-col-2>div{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
        background-color: white;
        padding: 20px;
        display: grid;
        gap: 20px;
    }
    
    .s-blog-col-2-head{
        background-color: #fe0000;
        color: white;
        font-weight: 600;
        font-size: 1.2rem;
        padding: 5px 20px;
    }
    
    
    .s-blog-col-2-list{
        box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 8px 0px;
        /* background-color: rgba(181, 181, 181, 0.166); */
        padding: 10px;
    }
    .s-blog-col-2-list-heading{
        color: #fe0000;
        font-size: 1.1rem;
        font-weight: 600;
    }
    
    
    
}
