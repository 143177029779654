.reason-bl{
    padding: 40px;
    background-image: url("../../media/images/progress.jpg");
    background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    text-align: center;
}
.reason-bl>div{
    max-width: 1170px;
    margin: auto;
}

.reason-bl-icon{
    color: #e40000;
    font-size: 1.6rem;
}
.reason-bl-heading-1{
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1;
}
.reason-bl-text{
    color: rgb(190, 202, 213);   
    max-width: 800px;
    margin: auto;
    padding: 30px 0 40px;
}
.reason-bl-text>b{
    color: white;
}
.reason-bl-button{
    background-color: #e40000;
    color: white;
    text-decoration: none;
    padding: 15px 40px;
    border-radius: 40px;
}

@media only screen and (max-width: 700px) {
    .reason-bl{
        padding: 30px 15px;
    }
    
    .reason-bl-heading-1{
        font-size: 1.8rem;
    }

    .reason-bl-text{
        padding: 20px 0 30px;
    }

    .reason-bl-button{
        padding: 10px 30px;

    }
    
}