.grooming-page-feature{
    padding: 50px;
    text-align: center;
}
.grooming-page-feature>div{
    max-width: 1170px;
    margin: auto;
}

@media only screen and (max-width: 700px) {

    .grooming-page-feature{
        padding: 30px 15px;
    }
}