.admin-home {
  display: flex;
  justify-content: center;
  padding: 30px;
}
.admin-home > div > a {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  font-size: 2rem;
  margin-top: 20px;
  font-weight: 500;
  color: black;
}
.admin-home > div > a:hover {
  color: red;
}
.admin-home > div > a > i {
  color: red;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.358) 0px 2px 8px 0px;
  padding: 10px;

  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

@media only screen and (max-width: 700px) {
  .admin-home {
    display: flex;
    justify-content: start;
    padding: 0px 20px;
  }
  .admin-home > div > a {
    gap: 10px;
    font-size: 1.5rem;
  }
  .admin-home > div > a > i {
    height: 40px;
    width: 40px;
  }
}
