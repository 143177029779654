
.privacy-policy-component{
    
    max-width: 1200px;
    margin: auto;
    padding: 40px 20px;

}


.privacy-policy-component>h1{
    text-align: center;
    margin-bottom: 20px;
}

.privacy-policy-component>h3,
.privacy-policy-component>h2{
    margin-top: 20px;
}
/* .privacy-policy-component>h3{
    margin-top: 10px;
} */

.privacy-policy-component>ul{
    padding-left: 40px;
}



