.boarding-page-price {
  background-color: rgba(128, 128, 128, 0.111);
  padding: 50px 0;
}
.boarding-page-price > div {
  max-width: 1170px;
  margin: auto;
}

.boarding-page-price-heading {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  margin-bottom: 20px;
}
.boarding-page-price-heading span {
  color: #fe0000;
}

@media only screen and (max-width: 700px) {

    .boarding-page-price {
        padding: 30px 15px;
      }
    
  .boarding-page-price-heading {
    font-size: 1.5rem;
  }
}
