
.boarding-form{
    margin: 60px;
}

.boarding-form>form{
    display: grid;
    gap: 40px;
    max-width: 1000px;
    margin: auto;
    box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36, inset -4px -4px 6px 0 #3e3e3e33, inset 4px 4px 6px 2px rgba(110, 110, 110, 0.201);
    border-radius: 20px;
    
}
.boarding-form>form>h2{
    margin-top: -30px;
    text-align: center;
        animation: anim-popoutin 3s ease infinite;
        color: black !important;
        font-weight: 500;
}

.boarding-form-heading-1{
    box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36, inset -4px -4px 6px 0 #3e3e3e33, inset 4px 4px 6px 2px rgba(110, 110, 110, 0.201);
    border-radius: 20px 20px 0 0;
    font-size: 1.5rem;
    background-color: red;
    color: white;
    font-weight: 700;
    text-align: center;
    padding: 10px;
    /* font-family: 'playpen sans'; */


    /* font-size: 2.1rem;
    font-weight: 1000;
    font-family: 'playpen sans';
    color: #fe0000;
    text-align: center; */
    
    
}

.boarding-form{}
.boarding-form-input{
    padding: 0 30px; 
    display: grid; 
    grid-template-columns: 2fr 7fr;
    /* align-items: center; */
}
.boarding-form-input>label{
    font-size: 1.1rem;
    font-weight: 600;
}

.boarding-form-input>input,
.boarding-form-input>textarea{
    padding: 5px;
    font-size: 1rem;
    border-radius: 10px;
    border: solid 1px gray;
}

.boarding-form>form>button{
    background-color: red;
    color: white;
    border-radius: 20px;
    width: 300px;
    margin: auto;
    border: none;
    margin-bottom: 30px;
    padding: 10px;
    font-size: 1.3rem;
    font-weight: 600;
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .5), -4px -4px 6px 0 rgba(116, 125, 136, .5), inset -4px -4px 6px 0px rgba(255, 255, 255, .2), inset 4px 4px 6px 2px rgba(0, 0, 0, .4);
}

@media only screen and (max-width: 700px) {
.boarding-form{
    margin: 40px 20px;
}
.boarding-form>form{
    gap: 30px;
}

.boarding-form>form>h2{
    text-align: center;
        animation: anim-popoutin 3s ease infinite;
        color: black !important;
        font-weight: 500;
        font-size: 1rem;
        /* width: auto; */
        max-width: 300px;
        margin: -20px auto 0;
}
    
.boarding-form-heading-1{
    font-size: 1.2rem;
    font-family: poppins;
}
    
.boarding-form-input{
    grid-template-columns: 1fr;
    padding: 0 20px;
    gap: 5px;
}
    
.boarding-form-input>label{
    font-size: 1rem;
}


.boarding-form-input>input,
.boarding-form-input>textarea{
    padding: 5px;
    font-size: .9rem;
    border-radius: 10px;
    border: solid 1px gray;
    
    border: solid 2px rgba(114, 114, 114, 0.607);
}

.boarding-form>form>button{
    width: 200px;

    padding: 5px;
    font-size: 1.2rem;
}


}


@media only screen and (max-width: 375px) {
    .boarding-form-heading-1{
        font-size: 1.1rem;
    }
}