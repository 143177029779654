
.top-btn {
    position: fixed;
    right: 20px;
    bottom: 40px;
  }
  
  .top-btn button {
    color: white;
    /* padding: 10px; */
    width: 60px;
    height: 60px;
    border: solid 2px;
    font-size: 1.8rem;
    border-radius: 50%;
    background-color: red;
  }
  
  @media only screen and (max-width: 700px) {
    .top-btn {
      position: fixed;
      right: 10px;
      bottom: 10px;
    }
  
    .top-btn button {
      color: white;
      width: 40px;
      height: 40px;
      border: solid 2px;
      font-size: 1.2rem;
      border-radius: 50%;
      background-color: red;
    }
  }