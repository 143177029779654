.our-location{
  /* background-color: rgba(128, 128, 128, 0.111); */
  
}
/* -------------------- */
.grooming-service {
  padding: 60px 40px;
}

.grooming-service>div{
  max-width: 1220px;  
  margin: auto;
  }
  
  .grooming-service-heading {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
  }
  .grooming-service-heading>span{
    color: #fe0000;
  }
  
  .grooming-service-content{
    display: flex;
    justify-content: space-between;
    margin: auto;
    padding-top: 30px;
    
  }
  
  .grooming-service-content-col{
    color: black;
    text-decoration: none;
    padding: 15px 20px;
    border-radius: 20px;
    text-align: center;
      /* background: linear-gradient(to bottom right, #ffffff, #ffffff); */
      background-color: white;
      /* box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px; */
      box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36, inset -4px -4px 6px 0 #3e3e3e33, inset 4px 4px 6px 2px rgba(110, 110, 110, 0.201);
  
  }
  
  .grooming-service-content-col-img img{
    width: 100px;
    border-radius: 20px;
    border-radius: 50%;
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
    /* box-shadow: -4px -4px 6px 0 #bbbbbb80, -4px -4px 6px 0 #747d8836, inset -4px -4px 6px 0 #8a8a8a33, inset 4px 4px 6px 2px rgba(0,0,0,.201); */
  
  }
  .our-location-content-col-img img{
    width: 100px;
    border-radius: 20px;
    border-radius: 50%;
    box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px;
   
  }
  
  .grooming-service-content-col-text{
    font-weight: 600;
  }
  /* ------------------------------------- */
  .location-service-content-col-text{
  
    font-weight: 600;
  }
  .location-service-content-col-text>span{
    color: #fe0000;
  }
  
@media only screen and (max-width: 800px) {
  
  
  .grooming-service {
    padding: 30px 0;
  }

  
  .grooming-service-heading{
    font-size: 1.5rem;
    margin: auto;
    padding: 0 15px;
  }
  
  .grooming-service-content{
    overflow: auto;
    width: 100%;
    padding: 10px 15px;
    justify-content: start;
    gap: 25px;
  }
  
  
.grooming-service-content-col-img img{
  width: 70px;
  margin: 0 5px;
}

.our-location-content-col-img img{
  width: 90px;
  /* margin: 0 5px; */
}


.grooming-service-content-col-text{
  font-size: .8rem;
}

/* ------------------------------- */
.location-service-content-col-text{
  font-size: .9rem;
  min-width: 105px;
}
.location-service-content-col-text-1{
  min-width: 120px;
}
  
}