.footer-main{
  background: url("../../media/images/ftr-bg.jpg");
  background-position: center;
  background-size: cover;

}
.footer-main>div {
  padding-top: 30px;
  background: rgba(0, 0, 0, 0.6);
  /* background: url("../../media/images/footer-bg.jpeg"); */
  color: white;
}
  .footer-content {
  max-width: 1300px;
  margin: auto;
}
.footer-content svg {
  transform: rotate(180deg);
  fill: white;
  margin-top: -2px;
}

.footer-logo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-logo-icon img {
  width: 25rem;
  background-color: white;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px 0 #afafaf80, -4px -4px 6px 0 #747d8836, inset -4px -4px 6px 0 #8a8a8a33, inset 4px 4px 6px 2px rgba(0,0,0,.201);
}

.footer-logo-text-1 {
  font-size: 2rem;
  font-family: playpen sans;
  font-weight: 800;
  color: #ce0000;
}
.footer-logo-text-1 > span {
  color: rgb(255, 255, 255);
}
.footer-logo-text-2 {
  font-family: poppins;
  font-size: 1.1rem;
  color: white;
}

.footer-social {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 20px 0;
}

.footer-social i {
  color: rgb(188, 188, 188);
  font-size: 1.5rem;
  border: solid 1.6px;
  padding: 8px;
  border-radius: 50%;
}

.footer-c-c2-section {
  /* color: white; */
}


.footer-c-col-2{
    display: flex;
    justify-content: space-evenly;
    padding: 40px 0 ;
}

.footer-c-c2-s-heading{
    font-weight: 700;    
    font-size: 1.2rem;
    padding-bottom: 4px;
    position: relative;
}

.footer-c-c2-s-heading:before{
    content: "";
    position: absolute;
    bottom: 0;
    display: inline-block;
    width: 50px;
    height: 2px;
    background-color: white;
}

.footer-c-c2-s-points{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    /* display: grid; */
    /* align-items:; */
    /* gap: 20px; */
    height: 190px;
    color: rgb(189, 189, 189);
  }
  
  .footer-c-c2-s-points >a{
  color: rgb(189, 189, 189);
  text-decoration: none;
 
}
.footer-c-c2-s-points > div{
    display: flex;
    align-items: center;
    /* margin-top: 15px; */
}
.footer-c-c2-s-points > a > span,
.footer-c-c2-s-points > div > span{
    width: 1rem;
    margin-right: 15px;
    text-align: center;
    color: white;
    /* padding: 10px; */
}

.footer-c-c2-s-points> a:hover{
  color: white;
  text-decoration: underline;
}

.footer-c-c2-s-form{
    background: gray;
    display: flex;
    height: 35px;
}

.footer-c-c2-s-form input{
    width: 100%;
    height: 35px;
    border: solid white;
    padding-left: 10px;
}

.footer-c-c2-s-form button{
    width: 120px;
    height: 35px;
    background-color: #ce0000;
    color: white;
    border: solid;
}

.footer-c-c2-s-para{
    line-height: 1.5;
}


.footer-c-cc {
    padding: 15px;
    font-size: 1rem;
    text-align: center;
    border-top: 2px solid rgba(172, 172, 172, 0.422);
    font-weight: 600;
    color: #afafaf;
  }
  


@media only screen and (max-width: 1000px) {
  .footer-content {
    border-radius: 0;
  }

  .footer-content svg {
    /* fill: transparent; */
  }
  .footer-logo-icon {
    width: 70%;
    margin: auto;
  }
  .footer-logo-icon img {
    width: 100%;

    /* width: 15rem; */
    
  /* padding: 5px 15px; */
  border-radius: 5px;
    }

  .footer-logo-text-1 {
    font-size: 1.4rem;
  }

  .footer-logo-text-2 {
    font-size: 0.8rem;
  }

  .footer-social {
    gap: 0px;
    justify-content: space-evenly;
    margin: 0 20px;
  }

  .footer-social i {
    color: rgb(188, 188, 188);
    font-size: 1rem;
    border: solid 1.6px;
    padding: 8px;
    border-radius: 50%;
  }

  .footer-c-col-2{
    /* flex-direction: column; */
    flex-wrap: wrap;
    padding: 0 30px;
    justify-content: space-between;
    gap:  30px;
  }

  
.footer-c-c2-s-points{
    margin-top: 0px;
}

.footer-c-cc{
  font-size: .9rem;
  padding: 20px 0;
}
  
  
}
