
@import url('https://fonts.googleapis.com/css2?family=Rum+Raisin&display=swap');

.p-boarding-header{    
    background: url("../../media/images/ftr-bg.jpg");
    background-position: center;
    background-size: cover;
}
.p-boarding-header>div{
    /* color: white; */
    /* color: #333544; */
    background: rgba(116, 8, 8, 0.6);
    background: rgba(242, 147, 147, 0.707);
    background: #84c8c9;
    background: rgba(255, 180, 180, 0.742);
    background: #d0d0d0a8;
    padding: 10px 0 50px;
}    
.p-boarding-header>div>div{
    max-width: 1170px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    align-items: center;
    margin: auto;
    gap: 50px;
}
.p-boarding-header-col-1{
    display: flex;
}
.p-boarding-header-col-1>img{
    width: 95%;
    /* height: 300px; */
}

.p-boarding-header-col-2-content{
display: grid;
gap: 10px;
padding: 40px 0;
}
.p-boarding-header-col-2-heading-1{
    /* font-family: "nunito"; */
    font-weight: 700;
    /* font-family: "Rum Raisin"; */
    font-size: 2.8rem;
    /* text-shadow: 0 0 0.6rem #ffe6ff, 0 0 1.5rem #ff65bd,
    -0.2rem 0.1rem 1rem #ff65bd, 0.2rem 0.1rem 1rem #ff65bd,
    0 -0.5rem 2rem #ff2483, 0 0.5rem 3rem #ff2483; */
    
}
.p-boarding-header-col-2-heading-1 span{
color: #fe0000;
}
.p-boarding-header-col-2-heading-2{
    font-size: 1.2rem;
    font-weight: 500;
}
.p-boarding-header-col-2-heading-3{
    margin: 20px 0 10px;
}
.p-boarding-header-col-2-heading-3>span{
    background: #ff8080;
    background: #fe0000;
    color: white;
    padding: 5px 30px;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1.1rem;

}

.p-boarding-header-col-2-columns{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
/* padding: 20px 0; */
}
.p-boarding-header-col-2-col{
    display: flex;
    gap: 10px;
    align-items: center;
}
.p-boarding-header-col-2-col-icon{
    height: 45px;
    width:  45px;
    font-size: 1.3rem;
    /* color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff6f6f;
    background: #fe0000;
    color: white;
    border-radius: 50px;
}
.p-boarding-header-col-2-col-text{
    font-weight: 600;
    font-size: 1.2rem;
}
    

.p-boarding-header-col-2-btn{
    display: none;
}

    
/*------------------------------------  */

.p-boarding-form>form{
background-color: #666666ad;
padding: 20px 50px;
/* max-width: 1200px; */
width: 90%;
margin: auto;
margin-top: -50px;
display: grid;
gap: 10px;
}
.p-boarding-form-col-1{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr  ;
    gap: 10px;
    align-items: center;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    font-weight: 700;
    
}
.p-boarding-form-col-2{
    display: grid;
    grid-template-columns: 3fr 3fr 3fr 3fr 2fr;
    gap: 10px;
    align-items: center;
}
.p-boarding-form>form input,
.p-boarding-form>form select{
    padding: 10px;
    border-radius: 100px;
    border: #ffffff solid;
    font-size: 1rem;
}
.p-boarding-form>form button{
    height: 100%;
    border: none;
    color: white;
    background: #fe0000;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 100px;
}

@media only screen and (max-width: 700px) {
    
    
@import url('https://fonts.googleapis.com/css2?family=Rum+Raisin&display=swap');

.p-boarding-header{    
    background: url("../../media/images/ftr-bg.jpg");
    background-position: center;
    background-size: cover;
}
.p-boarding-header>div{
    padding: 0px 0 00px;
}    
.p-boarding-header>div>div{
    display: grid;
    grid-template-columns: 1fr;
}
.p-boarding-header-col-1{
    display: none;
}
/* -------------------------------- */

.p-boarding-header-col-2-content{
padding: 20px 15px;
}
.p-boarding-header-col-2-heading-1{
    font-size: 1.5rem;
}
.p-boarding-header-col-2-heading-1 span{
color: #fe0000;
}
.p-boarding-header-col-2-heading-2{
    font-size: 1.1rem;
}
.p-boarding-header-col-2-heading-3{
    margin: 10px 0 10px;
}
.p-boarding-header-col-2-heading-3>span{
    padding: 5px 15px;
    font-size: .95rem;

}

.p-boarding-header-col-2-columns{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px 10px;
/* padding: 20px 0; */
}
.p-boarding-header-col-2-col{
    display: flex;
    gap: 10px;
    align-items: center;
}
.p-boarding-header-col-2-col-icon{
    height: 30px;
    width:  30px;
    font-size: .9rem;
    /* color: white; */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ff6f6f;
    background: #fe0000;
    color: white;
    border-radius: 50px;
}
.p-boarding-header-col-2-col-text{
    font-weight: 600;
    font-size: 1.1rem;
}
    

.p-boarding-form{
    display: none;
}
.p-boarding-header-col-2-btn{
    display: block;
    margin: 30px 0 10px;
    text-align: center;
}
.p-boarding-header-col-2-btn>span{
    background: #000000;
    color: white;
    font-weight: 600;
    padding: 7px 30px;
    border-radius: 20px;
}
    
}
