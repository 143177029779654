.package {
  /* background-color: #f2f2f2; */
  padding: 40px;
  text-align: center;
  background-color: rgba(128, 128, 128, 0.111);

}
.package > div {
  max-width: 1170px;
  margin: auto;
}

.package-head-icon {
  color: #fe0000;
  font-size: 1.6rem;
}
.package-head-heading {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.3;
}
.package-head-heading span{
  color: #fe0000;
}
.package-head-text {
  color: #fe0000;
  color: #fe0000;
  font-size: 1.1rem;
  font-weight: 500;
  margin-bottom: 45px;
}

.package-content-head {
  display: flex;
  justify-content: center;
  gap: 2px;
}
.package-content-head > div {
  padding: 15px 110px;
  border-radius: 30px 30px 0 0;
  font-weight: 500;
  background-color: #dddddd ;
}

.package-content-head-grey {
  background-color: #dddddd ;
  color: #000000;
}

.package-content-head-red {
  background-color: #fe0000 !important;
  color: white;
}

.package-content-list {
  border-top: #e34f61 solid 10px;
  background-color: white;
  box-shadow: 2px 0 20px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.package-content-list-heading {
  font-size: 2rem;
  font-weight: 700;
  padding: 20px;
}
.package-content-list-heading span{
  color: #fe0000;
}

.package-content-list-heading-1{
  color: #fe0000;
  padding-bottom: 10px;
  font-size: 1.2rem;
  font-weight: 600;
  animation: anim-popoutin 3s ease infinite;
  margin: -20px auto 0;
  max-width: 800px;
}

.package-content-list-columns {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 80px 30px;
  padding: 35px;
}
.package-content-list-col>div{
  border: 1px solid #e6e6e6;
  display: flex;
  flex-direction: column;
  height: auto;
}

.package-content-list-col-heading-1 {
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  font-size: 1.3rem;
  color: #fe0000;
  text-transform: uppercase;
  padding: 5px;
}

.package-content-list-col-heading-2 {
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  font-size: 1rem;
  padding: 10px;
}

.package-content-list-col-heading-3 {
  border-bottom: 1px solid #ddd;
  font-weight: 600;
  font-size: 1rem;
  padding: 5px;
  cursor: pointer;
}
.package-content-list-col-heading-2 > span {
  color: #fe0000;
  text-decoration: line-through;
}

.package-content-list-col-heading-4 {
  border-bottom: 1px solid #ddd;
  font-weight: 700;
  font-size: 1.2rem;
  /* color: #fe0000; */
  text-transform: uppercase;
  padding: 5px;
}
.package-content-list-col-heading-4 s{
  color: red;
}

.package-content-list-col-point {
  border-bottom: 1px solid #ddd;
  text-align: start;
  position: relative;
  
  padding: 6px 0 5px 30px;
}
.package-content-list-col-point:before {
  content: "";
  display: block;
  position: absolute;
  border: 1px solid #fe0000;
  left: 10px;
  top: 13px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
}

.package-content-list-col-button {
  margin: 20px auto 10px;
  padding: 10px 30px;
  border-radius: 30px;
  background-color: #fe0000;
  color: white;
  /* width: 200px; */
  width: max-content;
  text-decoration: none;
}
.package-content-list-col-button:hover{
  background-color: #2f373f;
  cursor: pointer;
}

/* --------------------------------------------- */
.package-training-margin-top{
    margin-top: 80px;
}

.package-content-head-1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3px;
}
.package-content-head-1 > div {
    background-color: #dddddd ;
  padding: 10px;
  border-radius: 30px 30px 0 0;
  font-weight: 500;
}



.package-content-head-2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3px;
}
.package-content-head-2 > div {
    background-color: #dddddd ;
  padding: 10px;
  border-radius: 30px 30px 0 0;
  font-weight: 500;
}
.package-content-head-2-red {
    background-color: #fe0000 !important;
    color: white !important;
}

.package-content-list-col-img-Dog{
    background: url("../../media/images/pet-growing.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    border-radius: 10px;
}

.package-content-list-col-img-Cat{
    background: url("../../media/images/cat-grow.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    border-radius: 10px;
}


.package-content-list-col-img-Pet-1{
    background: url("../../media/images/dog-train.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    border-radius: 10px;
}


.package-content-list-col-img-Pet-2{
    background: url("../../media/images/cat-train.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 300px;
    border-radius: 10px;
}

@media only screen and (max-width: 700px) {
  .package {
    padding: 30px 15px;
  }

  .package-head-heading {
    font-size: 1.5rem;
  }
  .package-head-text {
    font-size: .9rem;
    /* max-width: 360px; */
    margin: 0 auto 20px;
  }

  .package-content-head {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .package-content-head > div {
    padding: 5px;
    border-radius: 20px 20px 0 0;
  }

  .package-content-list-heading {
    font-size: 1.3rem;
    padding: 15px 10px;
  }
  .package-content-list-heading-1{
    margin: -10px 35px 10px;
    
    font-size: .87rem;
  }

  .package-content-list-columns {
    grid-template-columns: repeat(1, 1fr);
    gap: 40px;
    padding: 0 20px 20px 20px;
  }

  .package-content-list-col-heading-1 {
    font-size: 1.2rem;
    padding: 4px;
  }

  .package-content-list-col-heading-2 {
    font-size: 0.9rem;
    padding: 8px;
  }
  .package-content-list-col-point {
    font-size: 0.95rem;
  }

  .package-content-list-col-button {
    margin: 10px auto 10px;
    padding: 5px;
    font-size: 0.9rem;
    background-color: #fe0000;
    color: white;
    width: 200px;
  }

  

/* --------------------------------------------- */
.package-training-margin-top{
    margin-top: 50px;
}
.package-content-head-1 > div ,
.package-content-head-2 > div {
    padding: 5px;
    font-size: .85rem;
    border-radius: 20px 20px 0 0;
}
/* 
.package-content-list-col-img{
    min-height: 300px;
    border-radius: 10px;
} */
  
  
}
