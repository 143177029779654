.navbar-top {
  background: #3b3634;
  background: #181818;
  color: white;
  padding: 25px;
}
.navbar-top > div {
  max-width: 1170px;
  margin: auto;
  display: flex;
  justify-content: flex-end;
}
.navbar-top a {
  color: white;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
}
.navbar-top a:hover {
  color: red;
  text-decoration: underline;
}

.navbar-top-list {
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 140px;
  padding-right: 15px;
  font-size: 0.85rem;
  font-weight: 500;
}
.navbar-top-list i {
  font-size: 1.6rem;
  color: red;
}

/* -------------------------------- */

.navbar-bottom {
  background-color: #f2f1f0;
}
.navbar-bottom > div {
  max-width: 1170px;
  margin: auto;
}
.navbar-bottom a {
  color: black;
  text-decoration: none;
  font-weight: 500;
  /* font-size: 1.05rem; */
  /* font-family: 'Times New Roman', Times, serif; */
}
.navbar-bottom a:hover {
  color: red;
}
.navbar-bottom-menu {
  display: flex;
  /* justify-content: space-evenly; */
  justify-content: center;
  gap: 35px;
  padding: 35px 5px;
  align-items: center;
  /* float: right; */
}

.navbar-bottom-menu-button{
  background-color: #fe0000;
  padding: 5px 20px;
  border-radius: 30px;
  color: white !important;
}

/* ------------------------------------ */

.navbar-bottom-logo {
  position: relative;
  float: left;
  background: #ffffff;
  margin-top: -60px;
  margin-left: -120px;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  z-index: 1;
  padding: 29px 50px;
}

.navbar-bottom-logo > img {
  width: 300px;
  z-index: 2;
  position: relative;
}

.navbar-bottom-logo::before {
  position: absolute;
  content: "";
  background: #ffffff;
  width: 151%;
  width: 545px;
  height: 100%;
  right: 0px;
  top: 0px;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
}

/* -------------------------------------------------- */


.navbar-drop{
  cursor: grab;
  /* font-weight: 500; */
  
}
.navbar-drop-option{
  display: none;
  position: absolute;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 5px;
  display: none;
  z-index: 2;
  
}
.navbar-drop:hover .navbar-drop-option{
  /* display: block; */
  display: grid;
  gap: 10px;
  padding: 10px 25px 10px 15px;
  padding: 15px;
  background: white;
}


/* -------------------------------------------------- */


.navbar-small {
    display: none;
}

@media only screen and (max-width: 700px) {
  .navbar-small{
    display: block;
    background-color: white;
  }
  /* ----------------------------- */
  
  
.nav-head-top {
  background-color: black;
  background-color: #131a21;
  justify-content: space-evenly;
  align-items: center;
  display: flex;
  /* padding-left: 400px; */
    display: grid;
    text-align: center;

}
.nav-head-top-heading-1 {
  color: #fe0000;
  font-size: 1.1rem;
  font-weight: 700;
  animation: blink 4s linear infinite;
}
.nav-head-top-heading-2 {
  color: white;
  font-size: 0.8rem;
}



@keyframes blink {
  0%, 10% {
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  90%, 100% {
    opacity: 0;
  }
}
  /* ----------------------------- */
  
  .navbar {
    display: none;
  }
  .nav-small {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  }
  .nav-small-logo > img {
      width: 200px;
      /* width: 200px; */
      
    /* width: 11.5rem; */
    }
    .nav-small-icon{
        font-size: 1.6rem;
    }

    /* --------------------------------------------------- */
  

nav {
  position: fixed;
  bottom: 100vh;
  /* top: 0; */
  right: 0;
  height: 100%;
  width: 100%;
  transition: .5s;
  gap: 0px;
  background-color: #ffecec;
  z-index: 1;
}

.navbar-menu{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  gap: 15px;
}

.navbar-menu a{
  color: black;
  text-decoration: none;
  font-weight: 500;
  font-size: 1.1rem;
}
.responsive_nav {
  transform: translateY(100vh);
  z-index: 2;
}

.nav-close-btn {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 2rem;
  color: #fe0000;
}
    
}
