.breadcrumb{
    background: url("../../media/images/breadcrumbs.jpg");
    background-position: center;
    background-size: cover;
    color: white;
    height: 200px;
}
.breadcrumb>div{
    background: rgba(0, 0, 0, 0.79);
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.breadcrumb-heading-1{
    font-size: 2.5rem;
    font-weight: 500;
    text-align: center;
    /* color: #e40000; */
}
.breadcrumb-heading-2{
    font-size: 1.2rem;
    font-weight: 500;
}
.breadcrumb-heading-2>span{
    color: #e40000;
    
    text-shadow: #ffffffbe 0px 0px .5px;
}
.breadcrumb-heading-2>a{
    color: white;
    text-decoration: none;
}
.breadcrumb-heading-2>a:hover{
    color: #e40000;
}

@media only screen and (max-width: 600px) {
    .breadcrumb{
        height: 150px;
    }
.breadcrumb-heading-1{
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.5rem;
    margin-bottom: 10px;
}
.breadcrumb-heading-2{
    font-size: 1.1rem;
}
}