.welcome-ft{
    padding: 20px 0 50px;
    background-color: rgba(187, 187, 187, 0.156);

}
.welcome-ft>div{
    max-width: 1170px;
    margin: auto;
}

.welcome-ft-icon{
    text-align: center;
    color: #fe0000;
    font-size: 1.6rem;
}
.welcome-ft-heading-1{
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    /* line-height: 1; */
}
.welcome-ft-heading-1>span{
    color: #fe0000;
    color: #fe0000;
}
.welcome-ft-heading-2{
    text-align: center;
    color: #fe0000;
    color: #fe0000;
    font-size: 1.1rem;
    max-width: 650px;
    margin: auto;
}

.welcome-ft-col{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    padding: 30px 0;
}

.welcome-ft-col-1>img{
    width: 100%;
}

.welcome-ft-col-2-text{
    color: #667e96;   
}

.welcome-ft-col-2-points{
    margin: 20px 0 40px;
}
.welcome-ft-col-2-points>div{
    display: flex;
    gap: 10px;
    /* color: #344a5f; */
    font-weight: 500;
    /* margin-top: 10px; */
    align-items: center;
}
.welcome-ft-col-2-points>div>span{
    color: maroon;
    font-size: 1.5rem;
}

.welcome-ft-col-2-button{
    background-color: #fe0000;
    color: white;
    text-decoration: none;
    padding: 15px 40px;
    border-radius: 40px;
}
.welcome-ft-col-2-button:hover{
    background-color: #2f373f;
}


@media only screen and (max-width: 700px) {

    .welcome-ft{
        padding: 10px 15px 30px;
    }
    
    .welcome-ft-heading-1{
        font-size: 1.5rem;
    }
    .welcome-ft-heading-2{
        font-size: .85rem;
    }
    
    .welcome-ft-col{
        grid-template-columns: 1fr ;
        gap: 10px;
        padding: 20px 0;
    }
    
    .welcome-ft-col-2-text{
        color: #2f373f;
        font-size: .9rem; 
    }
    
    .welcome-ft-col-2-points{
        margin: 10px 0 10px;
    }
    
    .welcome-ft-col-2-button{
        padding: 5px 20px;
    }
    
    
}



