

.slick-arrow{
    display: none !important;
}


.slick-dots {
    display: none !important;
}

.header-slider>div{
    max-width: 1170px;
    margin: auto;
    padding: 80px 0;
    /* height: 75vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.header-slider>div>div{
    max-width: 590px;
}

.header-slider-heading-1{
    font-size: 3.8rem;
    color: #344a5f;
    line-height: 1;
    
}
.header-slider-heading-2{
    font-size: 3.8rem;
    font-weight: 700;
    color: #344a5f;
    line-height: 1;
margin-bottom: 30px;
}
.header-slider-text{
    font-size: 1.3rem;
    color: #667e96;
}
.header-slider-button{
    background-color: #fe0000;
    width: 250px;
    color: white;
    font-weight: 500;
    text-align: center;
    padding: 15px;
    border-radius: 40px;
    margin-top: 50px;
}
.header-slider-button:hover{
    background-color: #344a5f;
    cursor: pointer;
}



.header-slider-1{
    background: url(../../media/images/slide01.jpg);
}


.header-slider-2{
    background: url(../../media/images/slide02.jpg);
}


.header-slider-3{
    background: url(../../media/images/slide03.jpg);
}
.header-slider{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}



/* ------------------ why manscape end ------------------ */


@media only screen and (max-width: 600px) {

    .header-slider{
    }
    
    .header-slider>div{
        padding: 60px 15px;       
        height: auto;
    }

.header-slider>div{
    background-color: rgba(0, 0, 0, 0.5) ;
}
    

    .header-slider>div>div{
        /* max-width: 290px; */
    }
    
    .header-slider-heading-1{
        font-size: 1.8rem;
        color: #263747;
    color: white;
        
    /* text-shadow: #fff 1px 0 4px; */
        
    }
    .header-slider-heading-2{
        font-size: 1.8rem;
        color: #263747;
        margin-bottom: 15px;
    color: white;
        
        /* text-shadow: #fff 1px 1px 1px ; */
    }
    .header-slider-text{
        display: none;
        font-size: .9rem;
        color: #2c3339;
        width: 260px;
    }
    .header-slider-button{
        font-size: .9rem;
        width: 200px; 
        padding: 5px;
        border-radius: 40px;
        margin-top: 5px;
    }
    

}







