.error-page{
    padding: 120px;
    text-align: center;
}
.error-page-heading-1{
    
    font-size: 4rem;
    font-weight: 600;
}
.error-page-heading-2{
    
    margin-bottom: 50px;
}
.error-page-button > a{
    
    background-color: #fe0000;
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: 500;
    padding: 10px 50px;
    text-decoration: none;
}

@media only screen and (max-width: 700px) {
    .error-page{
        padding: 80px 15px;
    }
    .error-page-heading-1{
        
        font-size: 2.5rem;
        font-weight: 600;
    }
    .error-page-heading-2{
        
        margin-bottom: 50px;
    }
    .error-page-button > a{
        
    font-size: 1rem;
    padding: 8px 40px;
    }
}
