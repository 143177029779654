.our-mission{
    padding: 50px;
    padding-bottom: 0;

}
.our-mission>div{
    max-width: 1170px;
    margin: auto;
}
.our-mission-col{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.our-mission-icon{
    color: #fe0000;
    font-size: 1.6rem;
}
.our-mission-heading-1{
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1;
}
.our-mission-heading-1>span{
    color: #fe0000;
}
.our-mission-heading-2{
    color: #fe0000;
    font-size: 1.1rem;
    font-weight: 500;
    max-width: 650px;   
    margin: auto;
}

.our-mission-text-1{
    padding: 40px 0 30px;
}
.our-mission-text-2{
    color: #2c343c;
    margin-bottom: 50px;
}
.our-mission-button{
    
    background-color: #fe0000;
    color: white;
    text-decoration: none;
    padding: 15px 40px;
    border-radius: 40px;
}

.our-mission-col-2>img{
    width: 100%;
    display: flex;
}


@media only screen and (max-width: 700px) {
    .our-mission{
        padding: 30px 15px;
        padding-bottom: 0;

    }
    .our-mission-col{
        grid-template-columns: 1fr ;
        gap: 20px;
    }
    
    .our-mission-icon{
        text-align: center;
    }
    .our-mission-heading-1{
        text-align: center;
        font-size: 1.8rem;
    }
    .our-mission-heading-2{
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
    }
    
    .our-mission-text-1{
        padding: 20px 0px;
    }
    .our-mission-text-2{
        margin-bottom: 20px;
    }
    .our-mission-button{
        padding: 10px 30px;
    }
    
    .our-mission-col-2>img{
        width: 100%;
    }
    
}