.why-ft {
  padding: 40px;
  background: url("../../media/images/dog_bg.jpg");
  background-position:  center;
    background-repeat: no-repeat;
    background-size: cover;
}
.why-ft > div {
  max-width: 1170px;
  margin: auto;
}

.why-ft-icon {
  text-align: center;
  color: #fe0000;
  font-size: 1.6rem;
}
.why-ft-heading-1 {
  text-align: center;
  font-size: 2.2rem;
  font-weight: 700;
}
.why-ft-heading-1 span{
  color: #fe0000;
}
.why-ft-heading-2 {
  text-align: center;
  color: #fe0000;
  font-size: 1.1rem;
  max-width: 790px;
  margin: auto;
}

.why-ft-container{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 50px 0 20px;
}
.why-ft-col-1{
    text-align: end;
}
.why-ft-col-1,
.why-ft-col-2{
    display: grid;
    gap: 50px;
}
.why-ft-col-point{
    display: flex;
    gap: 20px;
}

.why-ft-col-heading{
    font-size: 1.2rem;
    font-weight: 700;
    padding: 0px 0 10px;
}

@media only screen and (max-width: 700px) {
    .why-ft {
        padding: 30px 15px;
        background: rgba(175, 175, 175, 0.115);
      }
    
      .why-ft-heading-1 {
        font-size: 1.8rem;
      }

      .why-ft-heading-2 {
        font-size: 1rem;
      }
      
      .why-ft-container{
          grid-template-columns: repeat(1, 1fr);
          padding: 20px 0 20px;
          gap: 20px;
      }

      .why-ft-col-1,
      .why-ft-col-2{
          display: grid;
          gap: 25px;
      }
      .why-ft-col-point{
          display: flex;
          gap: 20px;
      }
      
      .why-ft-col-heading{
          font-size: 1.2rem;
          font-weight: 700;
          padding: 0px 0 10px;
      }
}