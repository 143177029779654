.boarding-facility {
    /* background-color: rgba(128, 128, 128, 0.111); */
    padding: 50px;
}
.boarding-facility >div{
    max-width: 1170px;
    margin: auto;
}
.boarding-facility-content{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
}
.boarding-facility-col-heading{
    font-size: 2.2rem;
    font-weight: 700;
}
.boarding-facility-col-heading>span{
    color: #fe0000;
}
.boarding-facility-col-text-1{
    margin-top: 20px;
    font-size: 1.2rem;
    font-weight: 500;
    color: #fe0000;
}
.boarding-facility-col-text-2{
    margin-top: 20px;
    font-size: 1.1rem;
}

.boarding-facility-col img{
    width: 100%;
    padding-left: 30px;
}

@media only screen and (max-width: 700px) {
    .boarding-facility {
        padding: 30px 15px;
    }
    .boarding-facility-content{
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
    }
    .boarding-facility-col-heading{
        font-size: 1.5rem;
    }
    .boarding-facility-col-heading>span{
        color: #fe0000;
    }
    .boarding-facility-col-text-1{
        margin-top: 10px;
        font-size: 1.1rem;
        font-weight: 500;
        color: #fe0000;
    }
    .boarding-facility-col-text-2{
        margin-top: 10px;
        font-size: 1rem;
    }
    
    .boarding-facility-col img{
        width: 100%;
        padding: 10px 30px;
    }
    
}
