
.contact-mn {
  padding: 80px 80px 40px;
}


.contact-page {
  padding: 0 20px ;
  display: flex;
  gap: 60px;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.cont-map {
  border: gray solid;
  display: flex;
  padding: 20px;
  height: 450px;
  background-color: black;
  border-radius: 30px;
  width: 610px;
}

.cont-map iframe {
  border: rgb(137, 137, 137) 2px solid;
  width: 100%;
  border-radius: 15px;
}


.contact-col-2 {
  text-align: start;

}
.contact-col-2-h1 {
  color: #fe0000;
  font-weight: 700;
  font-size: 1.8rem;
}

.contact-col-2-h2 {
  font-weight: 700;
  font-size: 2.2rem;
}
.contact-col-2-h2 span{
  color: #fe0000;
}


.contact-col-2-h3 {
  font-size: 1.2rem;
  font-weight: 500;
}

.contact-col-2-h4 {
  font-size: 1rem;
  color: rgb(72, 72, 72);
  line-height: 40px;
  margin-top: 20px;
}

.contact-frm {
  display: grid;
  gap: 10px;
}

.contact-frm > div {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.contact-frm input,
textarea {
  padding: 10px;
  /* border: none; */
  font-family: poppins;

}
.contact-frm > button {
  font-family: poppins;
  font-size: large;
  font-weight: bold;
  background: #fe0000;
  color: rgb(255, 255, 255);
  padding: 8px;
  border: none;
}
.contact-frm > button:hover{
  cursor: pointer;
  background-color: #2f373f;
}

.contact-top {
  background-color: rgb(255, 255, 255);
  padding: 30px;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  max-width: 1200px;
  margin: 40px auto 0;

}

.c-t-col {
  display: flex;
  gap: 20px;
  align-items: center;
}

.c-t-icon {
  color: #fe0000;
  font-size: 2.5rem;
}

.c-t-t-h {
  font-weight: bold;
  font-size: large;
}
.c-t-t-t {
  font-size: 0.9rem;
  color: rgb(0, 0, 0);
}
  
  .contact-connect{
    font-size: 1.1rem;
    margin: 30px 60px 0;
    text-align: center;
    color: white;
  }


@media only screen and (max-width: 600px) {

  
  .contact-mn{
    padding: 30px 20px;
  }
  
  .contact-page{
    display: grid;
    justify-content: start;
    padding: 0;
    grid-template-columns: 1fr;
    gap: 30px;
  }
  
  .contact-col-1{
    width: 100%;
    margin: auto;
  }
  .cont-map{
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 0;
    height: 220px;
    border: none;
    background-color: black ;
    border-radius: 15px;
    
  }
  .cont-map iframe{
    padding: 10px;
    width: 95%;

  }

  
.contact-col-2-h1 {
  color: #fe0000;
  font-weight: 700;
  font-size: 1.3rem;
}

.contact-col-2-h2 {
  font-weight: 700;
  font-size: 1.5rem;
}
.contact-col-2-h3{
  font-size: 1rem;
}

  
  .contact-frm div{
    grid-template-columns: 1fr;
  }
  
  .contact-frm input,
  textarea {
    font-size: .9rem;
    padding: 5px;
  }

  .contact-top {
    /* margin: -10px; */
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 15px 0;
    display: grid;
    grid-template-columns: 1fr;

  }

  .c-t-col {
    padding-left: 30px;
    display: flex;
    gap: 20px;
    align-items: center;
  }

  .c-t-icon {
    /* color: red; */
    font-size: 1.4rem;
  }

  .c-t-t-h {
    font-weight: bold;
    font-size: 0.9rem;
  }
  .c-t-t-t {
    font-size: 0.7rem;
    color: rgb(185, 185, 185);
  }

  .contact-connect{
    font-size: .8rem;
    margin: 0px 5px 0;
    text-align: center;
    color: rgb(170, 170, 170);
  }

  
}


