
.blog-component{
    max-width: 1500px;
    margin: auto;
    display: flex;
position: relative;
    
}

.blog-component-col-1{
    /* position: fixed; */
    position: sticky;
    top: 100px;
    top: 0;
    height: 100vh;    
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    /* margin: auto; */
    z-index: -1;
    /* background-color: white; */
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
    /* padding:  60px; */
    min-width: 400px;
    text-align: center;
}

.blog-component-col-1-heading{
    margin-top: 50px;
    font-size: 2.8rem;
    font-weight: 700;
}
.blog-component-col-1-heading>span{
    color: #fe0000;
}
.blog-component-col-2{
    padding: 10px 80px;
}

.blog-component-col-2-heading-1{
    margin-top: 20px;
    font-size: 2.2rem;
    font-weight: 700;
}
.blog-component-col-2-heading-1 span{
    color: #fe0000;
}

.blog-component-col-2-heading-2{
    /* margin-top: 20px; */
    font-size: 2rem;
    font-weight: 700;
}
.blog-component-col-2-heading-2 span{
    color: #fe0000;
}

.blog-component-col-2-content{
    margin: 20px 0 50px;
    display: grid;
    gap: 40px;
}

.blog-component-col-2-content-col{
    box-shadow: rgba(0, 0, 0, 0.389) 0px 2px 8px 0px;
    background-color: white;
    padding: 30px;
    
}
.blog-component-col-2-content-col-heading-1{
    color: #fe0000;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 10px;
}
.blog-component-col-2-content-col-head{
    background-color: #fe0000;
    color: white;
    display: flex;
    justify-content: space-between;
    padding: 5px 20px;
    font-weight: 500;   
}
.blog-component-col-2-content-col-img{
    width: 90%;
    margin: auto;
    padding: 20px;
}
.blog-component-col-2-content-col-img>img{
    width: 100%;
    border-radius: 20px;
    
    box-shadow: 20px 15px 20px #00000066;
    box-shadow: 5px 5px 20px #00000066;

}

.blog-component-col-2-content-col-heading-2{
    font-size: 1.3rem;
    font-weight: 600;
}

.blog-component-col-2-content-col-button{
    margin: 10px 0 30px;
}
.blog-component-col-2-content-col-button>a{
    margin-bottom: 10px;
    background-color: #fe0000;
    color: white;
    text-decoration: none;
    padding: 5px 20px;
    border-radius: 30px;
}

/* ----------------------------------------- */

.blog-component-col-2-pagination{
    display: flex;
    justify-content: center;
    margin-top: -20px;
    margin-bottom: 40px;
}
.blog-component-col-2-pagination-buttons{
    display: flex;
    gap: 10px;
}

.blog-component-col-2-pagination-btn{
    border: #fe0000 solid 1px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.blog-component-col-2-pagination-btn-active{
    background-color: #fe0000;
    color: white;
}
.blog-component-col-2-pagination-btn-disable{
    background-color: #ffa8a8;
    color: white;
    border: none;
    cursor: no-drop;
}   

@media only screen and (max-width: 800px) {
    .blog-component-col-1{
        display: none;
    }
    
    .blog-component-col-2{
        margin-left: 0px;
        padding: 10px 15px;
    }
    
    .blog-component-col-2-heading-1{
        margin-top: 10px;
        font-size: 1.5rem;
        font-weight: 700;
    }
    .blog-component-col-2-heading-1 span{
        color: #fe0000;
    }
    
    .blog-component-col-2-heading-2{
        /* margin-top: 20px; */
        font-size: 1.5rem;
        font-weight: 700;
    }
    .blog-component-col-2-heading-2 span{
        color: #fe0000;
    }
    
    .blog-component-col-2-content{
        padding: 15px 0 50px;
        margin: 0;
    }
    
    .blog-component-col-2-content-col{
        padding: 10px;
        
    }
    .blog-component-col-2-content-col-heading-1{
        font-size: 1.25rem;
        /* font-weight: 600; */
    }
    .blog-component-col-2-content-col-head{
        padding: 2px 10px;
        font-weight: 500;   
    }
    .blog-component-col-2-content-col-img{
        width: 90%;
        margin: auto;
        padding: 10px 0px;
    }
    .blog-component-col-2-content-col-img>img{
        width: 100%;
        border-radius: 20px;        
        box-shadow: 20px 15px 20px #00000066;
    }
    
    .blog-component-col-2-content-col-heading-2{
        font-size: 1.2rem;
        font-weight: 600;
    }

    .blog-component-col-2-content-col-text{
        font-size: .9rem;
    }
    
    .blog-component-col-2-content-col-button{
        margin: 10px 0 20px;
    }

    /* ---------------------------- */

    
.blog-component-col-2-pagination{   
    margin-top: -30px;
}
.blog-component-col-2-pagination-buttons{
    gap: 7px;
}

.blog-component-col-2-pagination-btn{
    font-size: .9rem;
    width:  30px;
    height: 30px;
}
}



