.location-page-location{
    text-transform: capitalize;
    display: inline;
}

/* .location-page-header{
    background: url("../../media/images/Pet-Grooming-For-Cats.png");
    background-size: cover;
    background-position: bottom;
} */
.location-page-header{
    position: relative;
}
.location-page-header-image>img{
    width: 100%;
    display: flex;
}

.location-page-header-content{
    width: 100%;
    padding: 130px;
    position: absolute;
    top: 0;
    text-align: center;
}
.location-page-header-content-heading-1 span{
    font-size: 3.5rem;
    font-weight: 700;
    font-family: "playpen sans";
    text-shadow: #ffffff 3px 3px 0px;
}
.location-page-header-content-heading-2{
    font-family: "playpen sans";
    font-size: 3.5rem;
    font-weight: 900;
    text-shadow: #ffffff 3px 3px 0px;
}
.location-page-header-content-heading-2>span{
    font-family: "playpen sans";
    color: #ff0000;
}

@media only screen and (max-width: 700px) {
  
    .location-page-header-content{
        padding: 20px;
    }
    .location-page-header-content-heading-1 span{
        font-size: 1.5rem;
        font-weight: 700;
        font-family: "playpen sans";
        text-shadow: #ffffff 3px 3px 0px;
    }
    .location-page-header-content-heading-2{
        font-family: "playpen sans";
        font-size: 1.5rem;
        font-weight: 900;
        text-shadow: #ffffff 3px 3px 0px;
    }
    .location-page-header-content-heading-2>span{
        font-family: "playpen sans";
        color: #ff0000;
    }
    
}
