.testimonial-component{
  padding: 50px;
  /* background-color: rgba(255, 220, 220, 0.711); */
}
.testimonial-component>div{
  max-width: 1170px;
  margin: auto;
}
.testimonial-component-header {
  display: flex;
  justify-content: space-between;
  /* padding: 10px 150px; */
}

.testimonial-component-heading {
  /* padding: 30px 0 20px; */
  padding-bottom: 30px;
  font-size: 2.2rem;
  font-family: poppins;
  font-weight: 700;
  text-align: center;
}
.testimonial-component-heading>span{
  color: #fe0000;
}

.testimonial-component-header-col-1-heading {
  font-size: 1.5rem;
  font-family: "poppins";
  font-weight: 600;
}

.testimonial-component-header-col-1-text {
  font-size: 1.1rem;
}
.testimonial-component-header-col-1-text span {
  color: #f9d318;
}

.testimonial-component-header-col-2-button {
  background-color: #1269dc;
  background-color: #fe0000;
  color: white;
  padding: 10px 15px;
  font-family: poppins;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  text-decoration: none;
  cursor: pointer;
}
.testimonial-component-header-col-2-button:hover{
  background-color: #2f373f;
}

.testimonial-component-comments {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* padding: 20px 100px; */
  padding: 30px 0;
  gap: 20px;
  /* overflow: auto; */
}

.testimonial-component-comments-col {
  text-align: center;
  /* border: solid 2px rgba(186, 186, 186, 0.762); */
  box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8836, inset -4px -4px 6px 0 #8a8a8a33, inset 4px 4px 6px 2px rgba(0,0,0,.201);

  border-radius: 20px;
  padding: 30px;
  display: grid;
  gap: 10px;
  font-family: poppins;
  background-color: white;
}

.testimonial-component-comments-col-g-icon img {
  width: 30px;
}

.testimonial-component-comments-col-start {
  color: #f9cc18;
  font-size: 1.5rem;
}
.testimonial-component-comments-col-start>i{
  animation: hithere 1.8s ease infinite;

}


@keyframes bounce {
  70% { transform:translateY(0%); }
  80% { transform:translateY(-15%); }
  90% { transform:translateY(0%); }
  95% { transform:translateY(-7%); }
  97% { transform:translateY(0%); }
  99% { transform:translateY(-3%); }
  100% { transform:translateY(0); }
}

@keyframes bounce2 {
	0%, 20%, 50%, 80%, 100% {transform: translateY(0);}
	40% {transform: translateY(-30px);}
	60% {transform: translateY(-15px);}
}

@keyframes hithere {
  30% { transform: scale(1.2); }
  40%, 60% { transform: rotate(-20deg) scale(1.3); }
  50% { transform: rotate(20deg) scale(1.2); }
  70% { transform: rotate(0deg) scale(1.2); }
  100% { transform: scale(1); }
}

.testimonial-component-comments-col-name {
  font-weight: 700;
  font-size: 1.1rem;
}

.testimonial-component-comments-col-date {
  color: rgba(0, 0, 0, 0.623);
}

@media only screen and (max-width: 800px) {
    
  .testimonial-component{
    padding: 30px 0px 10px;
  }
  
  .testimonial-component {
    text-align: center;
    width: 100%;
  }

.testimonial-component-heading {
    font-size: 1.5rem;
    padding: 0;
}
  
  
  .testimonial-component-header {
    padding: 0px 10px;
    display: grid;
    gap: 20px;
    width: 100%;
    justify-content: center;
  }

  .testimonial-component-comments-col {
    text-align: center;
    padding: 20px 10px 10px 10px;
    gap: 5px;
  }

  .testimonial-component-header-col-1-heading {
    font-size: 1rem;
    font-family: "poppins";
    font-weight: 600;
  }

  
.testimonial-component-header-col-1-text {
  font-size: 1rem;
}

.testimonial-component-header-col-2-button {
  
}

  .testimonial-component-comments {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    padding: 30px 20px;
    gap: 20px;
    overflow: auto;
  }

  .testimonial-component-comments-col-text {
    font-size: 0.9rem;
    width: 250px;
  }

  .landing-package-heading-1 {
      font-size: 1.4rem !important;
      padding: 0 10px;
    }
    .landing-package-heading-2 {
        font-size: 1rem !important;
        display: none;
        padding: 0 40px;
    }

    
.testimonial-component-comments-col-date {
    font-size: .9rem;
}
    
    
}
