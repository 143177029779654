.pet-boarding{
    padding: 50px;
    background-color: rgba(128, 128, 128, 0.111);
}
.pet-boarding>div{
    max-width: 1170px;
    margin: auto;
}

.boarding-heading-1{
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
}
.boarding-heading-1>span{
    color: #fe0000;
}
.boarding-cols{
    background-color: white;
    padding: 20px 30px;
    border-radius: 50px;
    /* box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px; */
    /* box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8836, inset -4px -4px 6px 0 #8a8a8a33, inset 4px 4px 6px 2px rgba(0,0,0,.201); */
    box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36, inset -4px -4px 6px 0 #3e3e3e33, inset 4px 4px 6px 2px rgba(110, 110, 110, 0.201);

  }
  
  .boarding-cols{
    display: grid;
    grid-template-columns: 3fr 4fr;
    
    align-items: center;
  }
  
  .boarding-col-1{
    margin: auto;
  }
  .boarding-col-1 img{
    width: 80%;
    width: 350px;
    /* text-align: center; */
    margin: auto;
    /* padding: 0 0 0 40px; */
  }
  
  .boarding-col-2-heading{
    font-size: 1.8rem;
    color: #fe0000;
    font-weight: 700;
    margin-bottom: 20px;
  }
  
  
  .boarding-col-2-points{
    display: grid;
    gap: 10px;
  }
  .boarding-col-2-point{
    font-size: 1.1rem;
    
  }
  
  .boarding-col-2-point>i{
    color: #fe0000;
  }
  
@media only screen and (max-width: 800px) {
    .pet-boarding{
        padding: 30px 15px;
    }

    
.boarding-heading-1{
    font-size: 1.5rem;
    margin-bottom: 10px;
}
    
    .boarding-cols{
        padding: 10px 20px 30px;
        border-radius: 20px;
        /* box-shadow: rgba(129, 129, 129, 0.973) 0px 2px 8px 0px; */
      }
      
      .boarding-cols{
        display: grid;
        grid-template-columns: 1fr;
        align-items: center;
      }
        
      .boarding-col-1{
        text-align: center;
      }
      
      
      .boarding-col-1 img{
        /* width: 80%; */
        /* margin: auto; */
        width: 200px;
        text-align: center;
        /* padding: 0 80px; */
      }
      
      .boarding-col-2-heading{
        font-size: 1.2rem;
        margin-bottom: 10px;
      }
      .boarding-col-2-points{
        padding: 0 15px;
        gap: 5px;
      }
      .boarding-col-2-point{
        font-size: 1rem;
      }
}