.boarding-feature {
  padding: 50px;
  text-align: center;
}
.boarding-feature > div {
  max-width: 1170px;
  margin: auto;
}

.boarding-feature-heading {
  font-size: 2.2rem;
  font-weight: 700;
}
.boarding-feature-heading > span {
  color: #fe0000;
}

.boarding-feature-points{
    display: grid;
    grid-template-columns: 1fr 2fr 4fr;
    padding: 30px;
    max-width: 900px;
    gap: 50px;
    margin: auto;
}
.boarding-feature-points-text{
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    text-align: start;
}
.boarding-feature-points-text>span{
    color: #fe0000;
    font-size: 1.5rem;
    font-weight: 1000;
}

.boarding-feature-button{
  background-color: #fe0000;
  width: 300px;
  padding: 10px;
  color: white;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 30px;
  margin: auto;
}
.boarding-feature-button:hover{
  background-color: #2f373f;
  cursor: pointer;
}


@media only screen and (max-width: 700px) {
    .boarding-feature {
        padding: 30px 15px;
      }
      
      .boarding-feature-heading {
        font-size: 1.5rem;
      }
      
      .boarding-feature-points{
          grid-template-columns: 1fr ;
          padding: 10px 0 10px; 
          gap: 0px;
      }
      .boarding-feature-points-text{
          align-items: start;
          font-size: 1rem;
          font-weight: 500;
      }
      .boarding-feature-points-text>span{
          font-weight: 700;
          margin-top: -6px;
          margin-bottom: 3px;
      }
      
      .boarding-feature-button{
          width: 250px;
          padding: 5px;
          font-size: 1rem;
      }
      
      
}
