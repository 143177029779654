.confirm-booking{
    padding: 120px;
    text-align: center;
}
.confirm-booking-heading-1{
    font-size: 3rem;
    font-weight: 700;
}

.confirm-booking-heading-2{
    font-size: 2rem;
    font-weight: 500;
}

.confirm-booking-heading-3{
    font-size: 1.5rem;
    font-weight: 500;
    margin-bottom: 50px;
}
.confirm-booking-heading-3>a{
    color: #fe0000;
}
.confirm-booking-link>a{
    font-size: 1.1rem;
    text-decoration: none;
    color: white;
    font-weight: 500;
    background-color: #fe0000;
    padding: 10px 50px;
    border-radius: 10px;
}

@media only screen and (max-width: 700px) {
    .confirm-booking{
        padding: 80px 15px;
    }
    .confirm-booking-heading-1{
        font-size: 2rem;
    }
    
    .confirm-booking-heading-2{
        font-size: 1.3rem;
    }
    
    .confirm-booking-heading-3{
        font-size: 1.2rem;
        font-weight: 500;
        margin-bottom: 30px;
    }
    .confirm-booking-heading-3>a{
        color: #fe0000;
    }
    .confirm-booking-link>a{
        font-size: 1rem;
        padding: 5px 30px;
    }
    
}


