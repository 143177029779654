.how-ft{
  padding: 50px;
  text-align: center;
  background-color: rgba(128, 128, 128, 0.111);
  
}
.how-ft>div{
  max-width: 1300px;
  margin: auto;
}
.how-ft-heading{
  font-size: 2.2rem;
  font-weight: 700;
}
.how-ft-heading>span{
  color: #fe0000;
}
.how-ft-content{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: 1fr 1fr 1fr 1.1fr 1fr;
  gap: 30px;
  padding: 30px 0;
}
.how-ft-col{
  padding: 20px 20px;
  border-radius: 50px;
  background-color: white;
  
  box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #ffffff36, inset -4px -4px 6px 0 #3e3e3e33, inset 4px 4px 6px 2px rgba(110, 110, 110, 0.201);

}
.how-ft-col-icon{
  color: #fe0000;
  font-size: 3rem;
  display: flex;
  justify-content: center;
}
.how-ft-col-icon>img{
  width: 120px;
}
.how-ft-col-heading{
  /* color: #fe0000; */
  font-size: 1.25rem;
  font-weight: 600;
  /* color: #fe0000; */
}
.how-ft-col-heading-1{
  color: #eebe00;
}

.how-ft-col-heading-2{
  color: #ff8a96;
}

.how-ft-col-heading-3{
  color: #ff9f7f;
}

.how-ft-col-heading-4{
  color: #236edf;
}

.how-ft-col-heading-5{
  color: #3de25c;
}

@media only screen and (max-width: 700px) {

  .how-ft{
      padding: 30px 15px;
  }

  .how-ft-heading{
      font-size: 1.5rem;
  }
  .how-ft-content{
      grid-template-columns: repeat(2, 1fr);
      gap: 20px 20px;
      padding: 10px 0 20px;
  }
  .how-ft-col{
      padding: 20px 10px;
      border-radius: 30px;
  }
  .how-ft-col-icon{
      font-size: 2rem;
  }
  .how-ft-col-icon>img{
    width: 80px;
  }
  .how-ft-col-heading{
      font-size: 1rem;
      font-weight: 600;
  }
  
  
  
  .how-ft-col-text{
  font-size: .8rem;

}

}